import React, { useEffect, useState } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import { cloneDeep } from "lodash";
import t from "counterpart";
import { AreaService } from "../Area/AreaService";
import employeeService from "../Employee/Employee.service";
import outletService from "./Outlet.service";
import { TableWrapper } from "../../libs/react-mpk/wrapper";

const defaultData = () => ({
  outletName: "",
  phoneNumber: "",
  email: "",
  address: "",
  postalCode: "",
  area: {
    id: null,
  },
});

const OutletForm = ({
  visible = false,
  onRequestClose = () => {},
  onSubmitRequest = () => {},
  firstTime = false,
  ...props
}) => {
  let [item, setItem] = useState(null);
  let [id, setId] = useState(null);
  let [ready, setReady] = useState(true);
  let [disabled, setDisabled] = useState(false);
  let [postData, setPostData] = useState({
    kode:"",
    nama: "",
    noTelepon: "",
    email: "",
    alamat: "",
    status: 1,
    area: {
      id: null,
    },
    outlet: null,
    npwpHead: {
      id: sessionStorage.getItem("npwpHeadId"),
    },
  });
  let [employee, setEmployee] = useState({
    nama: "",
    noTelepon: "",
    email: "",
    posOutlet: {
      id: null,
    },
  });
  let outletOwner =
    sessionStorage.getItem("outletOwner") &&
    JSON.parse(sessionStorage.getItem("outletOwner"));
  // console.log(employeeData)

  useEffect(() => {
    if (visible) {
      let selectedItem = defaultData();
      if (props.item) {
        // setId(0)
        let selectedItem = cloneDeep(props.item);
        // selectedItem.number = String(selectedItem.number);
      }
      setItem(selectedItem);
    } else {
      setItem(defaultData());
    }
    setReady(visible);
  }, [visible]);

  const getPostalCode = async (postalCode) => {
    try {
      let res = await AreaService.getByKodePos(postalCode);
      let { data } = res;
      return data;
    } catch (e) {
      return e.message;
    }
  };

  const submitData = async (item, callback) => {
    setDisabled(true);
    let tempData = postData;

    tempData.kode = item.outletCode;
    tempData.nama = item.outletName;
    tempData.noTelepon = item.phoneNumber;
    tempData.email = item.email;
    // data.pin = item.pin;
    tempData.area.id = item.area.id;
    tempData.alamat = item.address;
    tempData.npwpHead = { id: props.match.params.npwpHeadId };
  
    // console.log(tempData)

    if (item.id) {
      tempData.id = item.id;
      try {
        let res = await outletService.updateOutlet(tempData);
        // console.log(res)
        let { data, status } = res;
        if (status == 200) {
          TableWrapper.updateDataItem("multi-outlet", { id: data.id }, data);
          TableWrapper.reload("multi-outlet");
          setDisabled(false);
        }
        callback("success", false);
      } catch (err) {
        setDisabled(false);
        callback(err, true, false);
      }
    } else {
      try {
        let res = await outletService.createOutlet(tempData);
        // console.log(res)
        let { data, status } = res;
        // console.log(data)
        sessionStorage.setItem("outletId", data.id);
        if (status == 200) {
          TableWrapper.updateDataItem("multi-outlet", { id: data.id }, data);
          TableWrapper.reload("multi-outlet");
          if (firstTime) {
            let firstEmployee = {
              posOutlet: {},
            };
            firstEmployee.nama = outletOwner.npwpHead.namaNpwp;
            firstEmployee.noTelepon = outletOwner.npwpHead.noTelepon;
            firstEmployee.email = outletOwner.npwpHead.email;
            firstEmployee.posOutlet.id = sessionStorage.getItem("outletId");
            try {
              let result = await employeeService.createEmployee(firstEmployee);

              let { data, status } = result;
              if (status == 200) {
                sessionStorage.setItem("firstEmployee", JSON.stringify(data));
              }
            } catch (err) {}
          }

          setDisabled(false);
          if (firstTime) {
            onSubmitRequest();
          }
        }
        callback("success", false);
      } catch (err) {
        setDisabled(false);
        callback(err, true, false);
      }
    }
  };

  return (
    ready && (
      <DataForm
        baseId="outlet-form"
        title={
          id
            ? t.translate("modules.outlet.editForm")
            : t.translate("modules.outlet.createForm")
        }
        visible={visible}
        // data={item}
        onRequestClose={onRequestClose}
        asDialog={true}
        defaultData={props.item || defaultData()}
        definitions={[
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate("words.outletCode"),
            key: "outletCode",
            disabled: disabled,
            required: true,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate("words.outletName"),
            key: "outletName",
            disabled: disabled,
            required: true,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate("words.phone"),
            key: "phoneNumber",
            type: "number",
            disabled: disabled,
            required: true,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate("words.email"),
            type: "email",
            key: "email",
            disabled: disabled,
            required: true,
          },
          {
            inputType: DataForm.inputTypes.TEXTAREA,
            label: t.translate("words.address"),
            key: "address",
            disabled: disabled,
            required: true,
          },
          // {
          //   inputType: DataForm.inputTypes.INPUT,
          //   label: t.translate("words.pin"),
          //   type: "password",
          //   key: "pin",
          //   disabled: disabled,
          // },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate("words.postalCode"),
            type: "number",
            key: "postalCode",
            disabled: disabled,
            required: true,
          },
          // {
          // 	inputType: DataForm.inputTypes.CHECKBOX,
          // 	label: 'Boolean',
          // 	key: 'boolean'
          // },
          // {
          // 	inputType: DataForm.inputTypes.MULTI_CHECKBOX,
          // 	label: 'Multi Checkbox',
          // 	key: 'multiCheckbox',
          // 	options: [
          // 		{label: 'Cokelat', value: 'cokelat'},
          // 		{label: 'Susu', value: 'susu'},
          // 		{label: 'Keju', value: 'keju'}
          // 	]
          // },
          // {
          //   inputType: DataForm.inputTypes.PRE_DATA,
          // },
        ]}
        onChange={(data, key, value) => {
          if (key === "postalCode") {
            if (value.length === 5) {
              let temp = getPostalCode(value);
              temp
                .then((res) => {
                  if (typeof res === "object") {
                    data.area.id = res.id;
                  } else {
                    alert(res);
                  }
                })
                .catch((err) => {
                  alert(err);
                });
            }
          }

          return data;
        }}
        onSubmit={!disabled ? submitData : null}
      />
    )
  );
};

export default OutletForm;
