import React from "react";
import { FontIcon, List, ListItem } from "react-md";
import { Router, Switch, Route } from "react-router-dom";
import { PageWrapper } from "../libs/react-mpk/wrapper";
import MultiOutlet from "../modules/MultiOutlet/MultiOutlet";

const MenuMultiOutlet = ({ history, match, ...props }) => {
  // console.log(match)
  const npwpHeadId = match.params.npwpHeadId
  // const [optionTahun, setOptionTahun] = useState([])
  // const [defaultTahun, setDefaultTahun] = useState({ tahun: new Date().getFullYear().toString() })

  // const basePath = `/internal/application`

  // useEffect(() => {
  //   getOptionTahun();
  //   sessionStorage.removeItem('npwpHead')
  // }, [])

  // const getOptionTahun = async () => {

  //   let listYears = [];
  //   let currentYear = new Date().getFullYear();

  //   console.log(sessionStorage.getItem('tahunSobatBuku'), 'tahunSobatBuku')

  //   if (sessionStorage.getItem('tahunSobatBuku')) {
  //     setDefaultTahun({ tahun: sessionStorage.getItem('tahunSobatBuku') })
  //   }
  //   else {
  //     sessionStorage.setItem('tahunSobatBuku', currentYear);
  //   }

  //   listYears.push(currentYear.toString());
  //   for (let i = 1; i < 5; i++) {
  //     listYears.push((currentYear - i).toString());
  //   }
  //   setOptionTahun(listYears);

  // }

  var sidebarMenuActive = [
    {
      label: "Menu",
    },
    {
      label: "Menu Utama",
      children: [
        {
          label: "Pilih Outlet",
          index: "akun, npwp, akunNpwp",
          resourceUri: "",
          iconClassName: "mdi mdi-table",
          path: `/internal/application/${npwpHeadId}`,
          childPath: [],
        },
      ],
    },
  ];

  return (
    <>
      <PageWrapper
        inverseTheme={true}
        style={{ background: "#D14C21" }}
        sidebarCollapsible={true}
        sidebarDefaultCollapse={true}
        sidebarHeader={
          <>
            <List
              style={{
                width: "100%",
                background: "white",
                padding: "0px",
                height: "65px",
              }}
            >
              <ListItem
                style={{ marginTop: "-3px" }}
                secondaryText={'Kembali'}
                rightAddon={
                  <FontIcon
                    onClick={() => {
                      window.location.href = `/internal/pemilik-outlet`;
                      //history.push(`/product/company/${companyId}/daftar-spt`)
                    }}
                    iconClassName="mdi mdi-close"
                  ></FontIcon>
                }
              >
                {/* {`${spt.year} - ${spt.month}`} */}
              </ListItem>
            </List>
          </>
        }
        sidebarMenu={sidebarMenuActive}
      >
        <Router history={history}>
          <Switch>
            <Route
              path="/internal/application/:npwpHeadId"
              render={(props) => <MultiOutlet {...props} />}
            />
          </Switch>
        </Router>
      </PageWrapper>
    </>
  );
};

export default MenuMultiOutlet;
