import React, { useEffect, useState } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import productService from "../Product/Product.service";
import bahanBakuService from "./BahanBaku.service";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import {
  Button,
  Table,
  FontIcon,
  TableHeader,
  TableBody,
  TableCell,
  TableRow,
} from "react-md";
import iziToast from "izitoast";

var thousandSeparator = ".";
var decimalSeparator = ",";

const defaultData = () => ({
  namaBahanBaku: "",
  kodeRak: "",
  kodeOutlet: sessionStorage.getItem("outletCode"),
  rakId: null,
  kategori: "",
  kategoriId: null,
  unit: "",
  unitId: null,
  hargaJual: "",
  deskripsi: "",
  isTrackingPersediaan: false,
  stokPersediaan: "",
  isTrackingHargaPokok: false,
  hargaPokok: "",
  kodeBahanBaku: "",
  image: null,
});

const BahanBakuForm = ({
  visible = false,
  onRequestClose = () => {},
  onSubmitRequest = () => {},
  modalStore,
  ...props
}) => {
  let [ready, setReady] = useState(true);
  let [disabled, setDisabled] = useState(false);
  let [showImageForm, setShowImageForm] = useState(false);
  let [imageList, setImageList] = useState([]);
  let [deleteImageList, setDeleteImageList] = useState([]);
  let [rakList, setRakList] = useState([]);
  let [categoryList, setCategoryList] = useState([]);
  let [unitList, setUnitList] = useState([]);
  let outletCode = sessionStorage.getItem("outletCode");
  let [item, setItem] = useState(null);
  let [isEdit, setIsEdit] = useState(false);
  let [isKodeOutlet, setIsKodeOutlet] = useState(false);

  let [postData, setPostData] = useState({
    posOutletProdukDTO: {
      isTrackingPersediaan: false,
      stokPersediaan: 0,
      isTrackingHargaPokok: false,
      hargaPokok: 0,
      posOutlet: null,
      kodeOutlet: "",
      manajemenRak: {
        id: null,
        namaRak: "",
        kodeRak: "",
      },
      sbkProduk: {
        id: null,
        kode: "PP010",
        nama: "Macbook Pro 2016 10",
        jenis: 1,
        deskripsi: "",
        kategori: {
          id: null,
        },
        unit: {
          id: null,
        },
        npwpHead: null,
        pengaturan: {
          beliProduk: false,
          akunPembelian: null,
          hargaBeli: 0,
          jualProduk: false,
          akunPenjualan: null,
          hargaJual: 0,
          monitoringPersediaan: false,
          akunPersediaan: null,
          batasStokMinimum: 0,
        },
      },
    },
    produkGambarList: [
      {
        id: null,
        subPath: "",
        nama: "",
        dibuatOleh: null,
        tanggalDibuat: null,
        produk: null,
      },
    ],
    produkGambarHapusList: [],
  });

  const getRak = async () => {
    setTimeout(async () => {
      let res = await productService.getRakList(props.match.params.outletId);
      var rak = [];

      if (res.data.length === 0) {
        rak.unshift({
          value: null,
          label: `${t.translate("words.unrak")}`,
        });
      } else {
        res.data.map((d) => {
          rak.push({
            value: d.id,
            label: `${d.kodeRak} - ${d.namaRak}`,
          });
        });
      }
      setRakList(rak);
    }, 500);
  };

  const getCategory = async () => {
    setTimeout(async () => {
      let res = await productService.getCategoryList();
      var categories = [];

      if (res.data.length === 0) {
        categories.unshift({
          value: null,
          label: `${t.translate("words.uncategory")}`,
        });
      } else {
        res.data.map((d) => {
          categories.push({
            value: d.id,
            label: `${d.kode} - ${d.nama}`,
          });
        });
      }

      setCategoryList(categories);
      // setShowTaxobjectCode(true)
    }, 500);
  };

  const getUnit = async () => {
    setTimeout(async () => {
      let res = await productService.getUnitList();
      var units = [];

      if (res.data.length === 0) {
        units.unshift({
          value: null,
          label: `${t.translate("words.ununit")}`,
        });
      } else {
        res.data.map((d) => {
          units.push({
            value: d.id,
            label: `${d.kode} - ${d.nama}`,
          });
        });
      }

      setUnitList(units);
      // setShowTaxobjectCode(true)
    }, 500);
  };

  const initData = async () => {
    if (visible) {
      let selectedItem = defaultData();
      if (props.item) {
        try {
          let res = await bahanBakuService.getBahanBakuById(props.item);
          let { data } = res;

          setPostData(data);
          setImageList(data.produkGambarList);
          setDeleteImageList(
            data.produkGambarHapusList ? data.produkGambarHapusList : []
          );

          selectedItem.id = data.posOutletProdukDTO.id;
          selectedItem.namaBahanBaku = data.posOutletProdukDTO.sbkProduk.nama;

          selectedItem.kodeRak = data.posOutletProdukDTO.manajemenRak
            ? data.posOutletProdukDTO.manajemenRak.kodeRak
            : null;

          selectedItem.namaRak = data.posOutletProdukDTO.manajemenRak
            ? data.posOutletProdukDTO.manajemenRak.kodeRak +
              " - " +
              data.posOutletProdukDTO.manajemenRak.namaRak
            : null;

          selectedItem.rakId = data.posOutletProdukDTO.manajemenRak
            ? String(data.posOutletProdukDTO.manajemenRak.id)
            : null;

          selectedItem.kategori =
            data.posOutletProdukDTO.sbkProduk.kategori.nama;

          selectedItem.kategoriId =
            data.posOutletProdukDTO.sbkProduk.kategori.id;

          selectedItem.unit = data.posOutletProdukDTO.sbkProduk.unit.nama;
          selectedItem.unitId = data.posOutletProdukDTO.sbkProduk.unit.id;
          selectedItem.deskripsi = data.posOutletProdukDTO.sbkProduk.deskripsi;

          selectedItem.hargaJual =
            data.posOutletProdukDTO.sbkProduk.defaultHarga;

          selectedItem.isTrackingPersediaan =
            data.posOutletProdukDTO.isTrackingPersediaan;

          selectedItem.stokPersediaan = data.posOutletProdukDTO.stokPersediaan;

          selectedItem.kodeOutlet = sessionStorage.getItem("outletCode");
          if (outletCode) {
            // console.log("masuk validasi");
            setIsKodeOutlet(true);
          } else {
            // console.log("masuk validasi else");
            setIsKodeOutlet(false);
          }
          selectedItem.kodeBahanBaku =
            data.posOutletProdukDTO.sbkProduk.kode.substring(
              data.posOutletProdukDTO.sbkProduk.kode.indexOf("-") + 1
            );

          selectedItem.isTrackingHargaPokok =
            data.posOutletProdukDTO.isTrackingHargaPokok;
          selectedItem.hargaPokok = data.posOutletProdukDTO.hargaPokok;

          setIsEdit(true)
        } catch (err) {}
      }
      setItem(selectedItem);
    } else {
      setItem(defaultData());
      setIsEdit(false)
      setImageList([]);
      if (outletCode) {
        setIsKodeOutlet(true);
      } else {
        setIsKodeOutlet(false);
      }
    }

    getRak();
    getCategory();
    getUnit();
    setReady(visible);
  };

  const deleteImage = (index) => {
    modalStore.showConfirm({
      title: t.translate("words.delete"),
      children: t.translate("words.confirmHapus"),
      onSubmit: (callback) => {
        var imageListNew = imageList;

        //tambah di list gambar hapus
        var deleteImageListNew = deleteImageList;
        deleteImageListNew.push(imageListNew[index]);
        setDeleteImageList(deleteImageListNew);

        //hapus di list gambar
        imageListNew.splice(index, 1);
        setImageList(imageListNew);
        callback();
      },
    });
  };

  const submitData = async (item, callback) => {
    setDisabled(true);

    let tempData = postData;
    console.log(tempData);

    tempData.posOutletProdukDTO.kodeOutlet = item.kodeOutlet;
    tempData.posOutletProdukDTO.posOutlet = {
      id: props.match.params.outletId,
    };
    tempData.posOutletProdukDTO.sbkProduk.npwpHead = {
      id: props.match.params.npwpHeadId,
    };
    tempData.posOutletProdukDTO.isTrackingPersediaan =
      item.isTrackingPersediaan;

    // tempData.posOutletProdukDTO.stokPersediaan = item.isTrackingPersediaan
    //   ? item.stokPersediaan
    //   : 0;
    if (item.isTrackingPersediaan) {
      tempData.posOutletProdukDTO.stokPersediaan = item.stokPersediaan
        ? item.stokPersediaan
        : 0;
    } else {
      tempData.posOutletProdukDTO.stokPersediaan = 0;
    }

    tempData.posOutletProdukDTO.isTrackingHargaPokok =
      item.isTrackingHargaPokok;
    // tempData.posOutletProdukDTO.hargaPokok = item.isTrackingHargaPokok
    //   ? item.hargaPokok
    //   : 0;
    if (item.isTrackingHargaPokok) {
      tempData.posOutletProdukDTO.hargaPokok = item.hargaPokok
        ? item.hargaPokok
        : 0;
    } else {
      tempData.posOutletProdukDTO.hargaPokok = 0;
    }

    tempData.posOutletProdukDTO.sbkProduk.nama = item.namaBahanBaku;
    tempData.posOutletProdukDTO.sbkProduk.deskripsi = item.deskripsi;
    tempData.posOutletProdukDTO.sbkProduk.defaultHarga = item.hargaJual
      ? item.hargaJual
      : 0;
    tempData.posOutletProdukDTO.sbkProduk.jenis = item.jenisProduk;
    tempData.posOutletProdukDTO.sbkProduk.kode =
      item.kodeOutlet + "-" + item.kodeBahanBaku;
    tempData.posOutletProdukDTO.manajemenRak = {
      id: item.rakId ? item.rakId : null,
    };

    tempData.produkGambarList =
      imageList && imageList.length > 0 ? imageList : [];
    tempData.produkGambarHapusList =
      deleteImageList && deleteImageList.length > 0 ? deleteImageList : [];

    tempData.posOutletProdukDTO.manajemenRak = {
      id: item.rakId ? item.rakId : null,
    };

    if (item.kategoriId) {
      tempData.posOutletProdukDTO.sbkProduk.kategori.id = item.kategoriId;
    } else {
      tempData.posOutletProdukDTO.sbkProduk.kategori.id = null;
    }

    if (item.unitId) {
      tempData.posOutletProdukDTO.sbkProduk.unit.id = item.unitId;
    } else {
      tempData.posOutletProdukDTO.sbkProduk.unit.id = null;
    }
    
    if (item.id) {
      try {
        const res = await bahanBakuService.updateBahanBaku(tempData, item.id);
        let { data, status } = res;

        setDisabled(false);
        if (status == 200) {
          callback("success", false);

          // TableWrapper.updateDataItem("inventory-table", { id: data.id }, data);
          TableWrapper.reload("bahan-baku-table");
        }
      } catch (err) {
        // console.log(err, 'err')
        setDisabled(false);
        callback(err, true, false);
      }
    } else {
      try {
        const res = await bahanBakuService.createBahanBaku(tempData);
        let { data, status } = res;
        // console.log(data);
        setDisabled(false);
        if (status == 200) {
          callback("success", false);

          // TableWrapper.updateDataItem("inventory-table", { id: data.id }, data);
          TableWrapper.reload("bahan-baku-table");
        }
      } catch (err) {
        // console.log(err, 'err')
        setDisabled(false);
        callback(err, true, false);
      }
      onSubmitRequest();
    }
  };


  const closeForm = () => {
    setImageList([]);
    setPostData({
      posOutletProdukDTO: {
        isTrackingPersediaan: false,
        stokPersediaan: 0,
        isTrackingHargaPokok: false,
        hargaPokok: 0,
        posOutlet: null,
        kodeOutlet: "",
        manajemenRak: {
          id: null,
          namaRak: "",
          kodeRak: "",
        },
        sbkProduk: {
          id: null,
          kode: "PP010",
          nama: "Macbook Pro 2016 10",
          jenis: 1,
          deskripsi: "",
          kategori: {
            id: null,
          },
          unit: {
            id: null,
          },
          npwpHead: null,
          pengaturan: {
            beliProduk: false,
            akunPembelian: null,
            hargaBeli: 0,
            jualProduk: false,
            akunPenjualan: null,
            hargaJual: 0,
            monitoringPersediaan: false,
            akunPersediaan: null,
            batasStokMinimum: 0,
          },
        },
      },
      produkGambarList: [
        {
          id: null,
          subPath: "",
          nama: "",
          dibuatOleh: null,
          tanggalDibuat: null,
          produk: null,
        },
      ],
      produkGambarHapusList: [],
    });
    onRequestClose();
  };

  useEffect(() => {
    initData();
  }, [visible]);
  return (
    ready && (
      <DataForm
        baseId="bahan-baku-form"
        title={t.translate("modules.bahanBaku.form")}
        visible={visible}
        onRequestClose={() => closeForm()}
        asDialog={true}
        defaultData={item}
        definitions={[
          {
            inputType: DataForm.inputTypes.FILE_INPUT,
            label: t.translate("words.gambar"),
            key: "image",
            type: "file",
            accept: ".jpg,.jpeg,.png",
            show: showImageForm,
          },

          {
            label: "List Gambar",
            key: "imageList",
            render: (
              <div style={{ width: "100%" }}>
                <Button
                  theme="primary"
                  themeType="contained"
                  style={{ margin: 15 }}
                  onClick={() => {
                    setShowImageForm(true);
                  }}
                >
                  <FontIcon iconClassName="mdi mdi-plus" />
                  {t.translate("words.add")}
                </Button>
                <Table style={{ width: "100%", marginBottom: "25px" }}>
                  <TableHeader>
                    <TableRow>
                      <TableCell style={{ width: "10%" }}>
                        {t.translate("words.non")}
                      </TableCell>
                      <TableCell style={{ width: "90%" }}>
                        {t.translate("words.namaFile")}
                      </TableCell>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {imageList.map((d, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{d.nama}</TableCell>
                          <TableCell>
                            {/* <Button onClick={()=> console.log("Masuk")}>{t.translate("words.edit")}</Button> */}
                            <Button onClick={() => deleteImage(index)}>
                              {t.translate("words.delete")}
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
            ),
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate("words.namaBahanBaku"),
            key: "namaBahanBaku",
            disabled: disabled,
            required: true,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate("words.outletCode"),
            key: "kodeOutlet",
            disabled: isKodeOutlet,
            required: true,
            style: { width: "25%", marginRight: "2%" },
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: `${t.translate("words.kodeProduk")} (${t.translate(
              "words.alphanumeric"
            )})`,
            key: "kodeBahanBaku",
            disabled: disabled,
            required: true,
            style: { width: "73%" },
          },
          // {
          //   inputType: DataForm.inputTypes.SELECT,
          //   label: t.translate("words.jenisBahanBaku"),
          //   key: "jenisBahanBaku",
          //   theme: "filled",
          //   options: productTypeList.map((d) => ({
          //     value: d.id,
          //     children: t.translate(`words.${d.name}`),
          //   })),
          //   labelKey: "children",
          //   valueKey: "value",
          //   disabled: disabled,
          //   required: true,
          // },
          {
            inputType: DataForm.inputTypes.AUTOCOMPLETE,
            label: t.translate("words.rak"),
            key: "namaRak",
            labelKey: "label",
            valueKey: "label",
            data: rakList,
            disabled: disabled,
            required: true,
          },

          {
            inputType: DataForm.inputTypes.AUTOCOMPLETE,
            label: t.translate("words.kategori"),
            key: "kategori",
            // validation: "required",
            labelKey: "label",
            valueKey: "label",
            data: categoryList,
            disabled: disabled,
            required: true,
          },
          {
            inputType: DataForm.inputTypes.AUTOCOMPLETE,
            label: t.translate("words.unit"),
            key: "unit",
            // validation: "required",
            labelKey: "label",
            valueKey: "label",
            data: unitList,
            disabled: disabled,
            required: true,
          },
          {
            inputType: DataForm.inputTypes.INPUT_MASK_NUMBER,
            label: t.translate("words.hargaJualSatuan"),
            thousandSeparator: thousandSeparator,
            decimalSeparator: decimalSeparator,
            isNumericString: true,
            type: "text",
            key: "hargaJual",
            disabled: disabled,
            required: true,
          },
          {
            inputType: DataForm.inputTypes.TEXTAREA,
            label: t.translate("words.deskripsi"),
            key: "deskripsi",
            disabled: disabled,
          },
          {
            inputType: DataForm.inputTypes.CHECKBOX,
            label: t.translate("words.monitorPersediaanBarang"),
            key: "isTrackingPersediaan",
            disabled: isEdit
          },
          {
            inputType: DataForm.inputTypes.INPUT_MASK_NUMBER,
            label: t.translate("words.availableStock"),
            thousandSeparator: thousandSeparator,
            decimalSeparator: decimalSeparator,
            isNumericString: true,
            key: "stokPersediaan",
            disabled: (item) => isEdit || !item.isTrackingPersediaan
          },
          {
            inputType: DataForm.inputTypes.CHECKBOX,
            label: t.translate("words.CogsTracking"),
            key: "isTrackingHargaPokok",
            disabled: (item) => isEdit || !item.isTrackingPersediaan
          },
          {
            inputType: DataForm.inputTypes.INPUT_MASK_NUMBER,
            label: t.translate("words.CogsMeans"),
            thousandSeparator: thousandSeparator,
            decimalSeparator: decimalSeparator,
            isNumericString: true,
            key: "hargaPokok",
            disabled: (item) => isEdit || !item.isTrackingPersediaan || !item.isTrackingHargaPokok
          }
        ]}
        onChange={async (data, key, value) => {
          if (key == "image") {
            let res = await productService.uploadImage(value);
            if (res.status == 200) {
              let newImageList = imageList;
              newImageList.push(res.data);
              setImageList(newImageList);
              setShowImageForm(false);
              data["image"] = null;
            }
          }
          if (key === "kategori") {
            categoryList.map((d) => {
              if (d.label === value) {
                data.kategoriId = d.value;
              }
            });
          }

          if (key === "unit") {
            unitList.map((d) => {
              if (d.label === value) {
                data.unitId = d.value;
              }
            });
          }

          if (key === "namaRak") {
            rakList.map((d) => {
              if (d.label === value) {
                data.rakId = d.value;
              }
            });
          }

          if (key === "hargaJual") {
            if (value <0){
              data.hargaJual = 0;
            }
          }

          if (key === "stokPersediaan") {
            if (value <0){
              data.stokPersediaan = 0;
            }
          }

          if (key === "hargaPokok") {
            if (value <= 0){
              data.hargaPokok = 1;
            }
          }

          if (key === "isTrackingPersediaan") {
            if(value===false){
              data.isTrackingHargaPokok = false;
            }
          }

          return data;
        }}
        onSubmit={!disabled ? submitData : null}
      />
    )
  );
};

export default inject("modalStore")(observer(BahanBakuForm));
