import React, { useEffect, useState } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { sso } from "../libs/react-mpk/hocs";
// import { AkunNpwp } from '../modules';
import moment from "moment";
import MainMenu from "./MainMenu";
import outletOwnerService from "../modules/PemilikOutlet/PemilikOutlet.service";
import premiumService from "../modules/Premium/Premium.service";
import OutletForm from "../modules/Outlet/OutletForm";
import OutletOwnerForm from "../modules/PemilikOutlet/OutletOwnerForm";
import EmployeePinForm from "../modules/Employee/EmployeePinForm";
import MenuMultiOutlet from "./MenuMultiOutlet";
import MenuAkunNpwp from "./MenuAkunNpwp";
import PremiumForm from "../modules/Premium/PremiumForm";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogContent,
  DialogTitle,
  DialogFooter,
  Text,
  Card,
  CloseSVGIcon,
} from "react-md";
import PremiumNotif from "../modules/Premium/PremiumNotif";
import PremiumBanner from "../modules/Premium/PremiumBanner";
import PremiumPackage from "../modules/Premium/PremiumPackage";

const Internal = ({ history, ...props }) => {
  const [showDialogPremium, setShowDialogPremium] = useState(false);
  const [showNotifPremium, setShowNotifPremium] = useState(false);
  const [showOutletOwner, setShowOutletOwner] = useState(false);
  const [showCreateOutlet, setShowCreateOutlet] = useState(false);
  const [showPinForm, setShowPinForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const [showForm, setShowForm] = useState(false);

  const cekPremium = async () => {
    try {
      const res = await premiumService.cekPremium();
      let { data } = res;
      sessionStorage.setItem("premium", data.message);
      sessionStorage.setItem("tanggalBerakhir", data.tanggalBerakhir);

      // console.log(res);
    } catch (err) {}
  };

  const cekPemilikOutletExist = async () => {
    try {
      const res = await outletOwnerService.checkOutletOwnerExist();
      let { data } = res;
      // console.log(data)

      if (data.message === "Create NPWP Baru") {
        // props.navigationStore.redirectTo('')
        setShowOutletOwner(true);
      } else if (data.message === "Lanjut Dashboard") {
        // try {
        //   const res = await outletOwnerService.getNpwpHead();
        //   let { data } = res;

        //   // sessionStorage.setItem("npwpHeadId", data.npwpHead.id);

        //   if (res.data) {
        //     // let result = await outletService.getByNpwpHeadId(
        //     //   data.npwpHead.id
        //     // );
        //     // if (result.data) {
        //     //   sessionStorage.setItem("outletId", result.data.id);
        //     //   sessionStorage.setItem("outletName", result.data.nama);
        //     // }
        //   }

        //   // console.log(result.err)

        //   // if (result.status != 200){
        //   //   setShowCreateOutlet(true)
        //   // }
        // } catch (err) {
        //   // console.log(err);
        //   if (err.message.includes("Anda belum pernah membuat OUTLET")) {
        //     setShowCreateOutlet(true);
        //   }
        // }
      } else if (data.message === "Update NPWP") {
        try {
          let res = await outletOwnerService.migration();
        } catch (err) {}
      }
      // else if (data.message){

      // }
    } catch (err) {}
  };

  var dataTanggalBerakhir = sessionStorage.getItem("tanggalBerakhir");

  useEffect(() => {
    cekPemilikOutletExist();
    cekPremium();

    // var dataTanggalBerakhir = sessionStorage.getItem("tanggalBerakhir")

    if (moment(dataTanggalBerakhir).diff(new Date()) < 259200000) {
      setShowNotifPremium(true);
    }
    // cekTanggalPremium();
    // console.log(showNotifPremium);
    // console.log(localStorage.getItem('npwpHeadId'))
  }, []);

  return (
    <>
      {/* <PremiumNotif
        visible={showNotifPremium}
        onRequestClose={() => {
          setShowNotifPremium(false);
        }}
        {...props}
      />
      {sessionStorage.getItem("premium") == "User Ini Tidak Premium" && (
        <PremiumBanner
          visible={showDialogPremium}
          onRequestClose={() => {
            setShowDialogPremium(false);
          }}
          {...props}
        />
      )} */}
      <Router history={history}>
        <Switch>
          {/* <Route
            path="/internal/application"
            render={(props) => <MainMenu {...props} />}
          /> */}
          <Route path="/internal/npwp">
            <Redirect to="/internal/pemilik-outlet" />
          </Route>

          <Route
            path="/internal/pemilik-outlet"
            render={(props) => <MenuAkunNpwp {...props} />}
          ></Route>

          <Route
            path="/internal/application/:npwpHeadId/outlet/:outletId"
            render={(props) => <MainMenu {...props} />}
          />
          <Route
            path="/internal/application/:npwpHeadId"
            render={(props) => <MenuMultiOutlet {...props} />}
          />
        </Switch>
      </Router>

      <OutletForm
        visible={showCreateOutlet}
        onRequestClose={() => {
          setShowCreateOutlet(false);
          setSelectedItem(null);
        }}
        onSubmitRequest={() => {
          setShowPinForm(true);
        }}
        firstTime={true}
        npwpHeadId={sessionStorage.getItem("npwpHeadId")}
      />

      <OutletOwnerForm
        visible={showOutletOwner}
        onRequestClose={() => {
          cekPemilikOutletExist()
          setShowOutletOwner(false);
          setSelectedItem(null);
        }}
        onSubmitRequest={() => {
          setShowCreateOutlet(true);
        }}
        item={selectedItem}
      />

      <EmployeePinForm
        visible={showPinForm}
        onRequestClose={() => {
          setShowPinForm(false);
          setSelectedItem(null);
        }}
        firstTime={true}
        item={selectedItem}
      />

      {/* <AkunNpwpForm
        visible={showFormAkunNpwp}
        onRequestClose={() => {
          cekNpwpExist()
          setShowFormAkunNpwp(false)
          // window.location.reload()
        }}
      />
      <MigrasiDataAwal
        visible={showFormMigrasiDataAwal}
        onRequestClose={() => {
          cekNpwpExist()
          setShowFormMigrasiDataAwal(false)
        }}
      // itemId={selectedItemId}
      // isDisabled={disabledForm}
      /> */}
    </>
  );
};

export default sso({
  basePath: "/internal",
})(Internal);
