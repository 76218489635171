import React, { useState } from "react";
import {
  Button,
  Card,
  CloseSVGIcon,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "react-md";
import PremiumForm from "./PremiumForm";

const PremiumPackage = ({
  visible = false,
  onRequestClose = () => {},
  ...props
}) => {
  const [showPackagePremium, setShowPackagePremium] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  return (
    <>
      <Dialog
        id="main-dialog"
        visible={visible}
        onRequestClose={() => onRequestClose()}
        aria-labelledby="main-dialog-title"
      >
        <DialogHeader
          style={{
            paddingBottom: "5px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <DialogTitle id="main-dialog-title">Sobat Package</DialogTitle>
          <Button
            id="custom-themed-button-2"
            buttonType="icon"
            themeType="outline"
            theme="primary"
            aria-label="Close"
            onClick={() => onRequestClose()}
          >
            <CloseSVGIcon />
          </Button>
        </DialogHeader>
        <DialogContent style={{ paddingTop: "15px" }}>
          <h1 style={{ textAlign: "center" }}>SobatPOS</h1>
          <Card
            style={{
              width: "200px",
              justifyContent: "space-around",
              marginRight: "30px",
              backgroundColor: "#c6d4e1",
            }}
          >
            <table>
              <tr>
                <th>FREE</th>
              </tr>
              <tr>
                <td>
                  1. Hanya dapat diakses oleh satu karyawan dalam satu
                  waktu/outlet
                </td>
              </tr>
              <tr>
                <td>
                  2. Jumlah transaksi harian maksimum adalah 100 Transaksi
                </td>
              </tr>
              <tr>
                <td>3. Jumlah produk maksimum adalah 100 Produk</td>
              </tr>
              <tr>
                <td>4. Hanya dapat mendaftarkan 1 NPWP saja dalam satu akun</td>
              </tr>
            </table>
          </Card>
          <Card
            style={{
              width: "200px",
              justifyContent: "space-around",
              paddingBottom: "20px",
              backgroundColor: "#0868C1",
              color: "white",
            }}
          >
            <table>
              <tr>
                <th>PREMIUM</th>
              </tr>
              <tr>
                <td>
                  1. Dapat diakses oleh beberapa karyawan dalam waktu bersamaan
                </td>
              </tr>
              <tr>
                <td>2. Jumlah transaksi harian maksimum adalah tanpa batas</td>
              </tr>
              <tr>
                <td>3. Jumlah produk maksimum adalah tanpa batas</td>
              </tr>
              <tr>
                <td>4. Jumlah NPWP yang dapat didaftarkan tanpa batas</td>
              </tr>
            </table>
          </Card>
          <h1 style={{ textAlign: "center" }}>SobatBuku</h1>
          <Card
            style={{
              width: "200px",
              justifyContent: "space-around",
              marginRight: "30px",
              backgroundColor: "#c6d4e1",
            }}
          >
            <table>
              <tr>
                <th>FREE</th>
              </tr>
              <tr>
                <td>
                  1. Jumlah transaksi harian maksimum adalah 100 Transaksi
                </td>
              </tr>
              <tr>
                <td>2. Jumlah produk maksimum adalah 100 Produk</td>
              </tr>
              <tr>
                <td>3. Hanya dapat mendaftarkan 1 NPWP saja dalam satu akun</td>
              </tr>
            </table>
          </Card>
          <Card
            style={{
              width: "200px",
              justifyContent: "space-around",
              paddingBottom: "20px",
              backgroundColor: "#0868C1",
              color: "white",
            }}
          >
            <table>
              <tr>
                <th>PREMIUM</th>
              </tr>
              <tr>
                <td>1. Jumlah transaksi harian maksimum adalah tanpa batas</td>
              </tr>
              <tr>
                <td>2. Jumlah produk maksimum adalah tanpa batas</td>
              </tr>
              <tr>
                <td>3. Jumlah NPWP yang dapat didaftarkan tanpa batas</td>
              </tr>
            </table>
          </Card>
          {/* <div style={{display:'flex',justifyContent:'center',marginTop:'25px'}}>
          <Button id='langganan-button' theme='primary' themeType='contained' onClick={() => {setShowForm(true);}}>Langganan Sekarang</Button>
          </div> */}
          <PremiumForm
            visible={showForm}
            onRequestClose={() => {
              setShowForm(false);
              setSelectedItem(null);
            }}
            item={selectedItem}
          />
        </DialogContent>
        <DialogFooter
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button
            id="main-dialog-close"
            theme="clear"
            themeType="contained"
            onClick={() => onRequestClose()}
          >
            Langganan Nanti
          </Button>
          <Button
            id="langganan-button"
            theme="primary"
            themeType="contained"
            onClick={() => {
              setShowForm(true) && onRequestClose();
            }}
            style={{ color: "white" }}
          >
            Langganan Sekarang
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default PremiumPackage;
