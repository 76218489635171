import { crud, http } from '../libs/react-mpk/services';

let service = null
let serviceUrl = '/area/kodepos'
service = new crud('/area/kodepos');

service.getByKodePos = async(kodepos) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/${kodepos}`
  })
}

export default service;