import React, { useEffect, useState } from "react";
import { DataForm } from "../../libs/react-mpk/components";
import t from "counterpart";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import unitService from "./Unit.service";

const defaultData = () => ({
  kode: "",
  nama: "",
});

const UnitForm = ({ visible = false, onRequestClose = () => {}, ...props }) => {
  let [item, setItem] = useState(null);
  let [ready, setReady] = useState(true);
  let [postData, setPostData] = useState({
    kode: "",
    nama: "",
    npwpHead: {
      id: props.match.params.npwpHeadId,
    },
  });
  let [disabled, setDisabled] = useState(false);

  const initData = async () => {
    if (visible) {
      let selectedItem = defaultData();
      if (props.item) {
        try {
          let res = await unitService.getUnitById(props.item);
          let { data } = res;
          setPostData(data);

          selectedItem.id = data.id;
          selectedItem.kode = data.kode;
          selectedItem.nama = data.nama;
        } catch (err) {}

        // selectedItem = cloneDeep(props.item);
      } else {
        try {
          let ress = await unitService.generateCode(props.match.params.npwpHeadId);
          let { data, status } = ress;
          if (status === 200) {
            selectedItem.kode = data.message;
          }
        } catch (err) {}
      }
      setItem(selectedItem);
    } else {
      setItem(defaultData());
    }
    setReady(visible);
  };

  useEffect(() => {
    initData();
  }, [visible]);

  const submitData = async (item, callback) => {
    setDisabled(true);

    let postItem = postData;
    postItem.npwpHead.id = props.match.params.npwpHeadId;
    postItem.kode = item.kode;
    postItem.nama = item.nama;

    if (item.id) {
      try {
        const res = await unitService.updateUnit(postItem, item.id);
        let { data, status } = res;
        if (status === 200) {
          setDisabled(false);
          TableWrapper.updateDataItem("unit-table", { id: data.id }, data);
          TableWrapper.reload("unit-table");
        }
        // console.log(res, 'res')
        callback("success", false);
      } catch (err) {
        // console.log(err, 'err')
        setDisabled(false);
        callback(err, true, false);
      }
    } else {
      try {
        const res = await unitService.createUnit(postItem);
        let { data, status } = res;
        if (status === 200) {
          setDisabled(false);
          TableWrapper.updateDataItem("unit-table", { id: data.id }, data);
          TableWrapper.reload("unit-table");
        }
        // console.log(res, 'res')
        callback("success", false);
      } catch (err) {
        // console.log(err, 'err')
        setDisabled(false);
        callback(err, true, false);
      }
    }
  };

  const closeForm = () => {
    setPostData({
      kode: "",
      nama: "",
      npwpHead: {
        id: props.match.params.npwpHeadId,
      },
    });
    onRequestClose();
  };

  return (
    ready && (
      <DataForm
        baseId="unit-form"
        title={t.translate("modules.kategoriUnit.unit.form")}
        visible={visible}
        // data={item}
        onRequestClose={() => closeForm()}
        asDialog={true}
        defaultData={item}
        definitions={[
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate(`words.kode`),
            key: "kode",
            disabled: true,
          },
          {
            inputType: DataForm.inputTypes.INPUT,
            label: t.translate(`words.namaUnit`),
            key: "nama",
            validation: "required",
            disabled: disabled,
          },
        ]}
        onChange={(data, key, value) => {
          return data;
        }}
        onSubmit={!disabled ? submitData : null}
      />
    )
  );
};

export default UnitForm;
