import React, { useEffect, useState } from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import supplierService from "./Supplier.service";
import SupplierForm from "./SupplierForm";
import iziToast from "izitoast";
import SupplierDetail from "./SupplierDetail";

const Supplier = ({ className = "", showCommandbar = true, modalStore, ...props }) => {
  const [showForm, setShowForm] = useState(false);
  const [showDetailForm, setShowDetailForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {}, []);

  const deleteSupplier = async (item) => {
    try {
      let res = await supplierService.deleteSupplier(item.id);

      let { data, status } = res;

      if (status == 200) {
        iziToast.success({
          message: data.message,
        });

        TableWrapper.updateDataItem("supplier-table", { id: data.id }, data);
        TableWrapper.reload("supplier-table");
      } else {
        iziToast.info({
          message: data.message,
        });
      }
    } catch (err) {
      iziToast.error({
        message: err.message,
      });
    }
  };

  const deleteItem = async (item) => {
    modalStore.showConfirm({
      title: t.translate("words.delete"),
      children: t.translate("words.confirmHapus"),
      onSubmit: (callback) => {
        deleteSupplier(item);
        callback();
      },
    });
  };

  return (
    <>
      <TableWrapper
        baseId="supplier-table"
        title={t.translate("modules.lawanTransaksi.supplier.title")}
        className={className}
        hintMessage={t.translate(
          "modules.lawanTransaksi.supplier.hint.message"
        )}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate("modules.table.hint.more")}
        defaultData={[]}
        defaultSortBy="tanggalDibuat"
        defaultSort="ASC"
        isShowFilter={false}
        showActionColumn={true}
        useFilter={true}
        multiColumnFilter={true}
        defaultCollapse={true}
        useCriteria={true}
        selectable={false}
        useQueryHistory={false}
        // extraFilter={<div>Test</div>}
        columns={[
          {
            label: t.translate("words.name"),
            searchable: true,
            sortable: false,
            key: "nama",
            render: (item) => item.nama,
          },
          {
            label: t.translate("words.address"),
            searchable: true,
            sortable: false,
            key: "alamat",
            render: (item) => item.alamat,
          },
          {
            label: t.translate("words.phone"),
            searchable: true,
            sortable: false,
            key: "noTelepon",
            render: (item) => item.noTeleponPic,
          },
          {
            label: t.translate("words.email"),
            searchable: true,
            sortable: false,
            key: "email",
            render: (item) => item.emailPic,
          },
          // {
          //   label: "Number",
          //   searchable: true,
          //   key: "number",
          //   type: TableWrapper.dataTypes.NUMBER,
          //   render: (item) => item.number,
          // },
          // {
          //   label: "Boolean",
          //   type: TableWrapper.dataTypes.BOOLEAN,
          //   render: (item) => (
          //     <TableWrapper.Status
          //       type={
          //         item.boolean
          //           ? TableWrapper.Status.types.SUCCESS
          //           : TableWrapper.Status.types.ERROR
          //       }
          //     >
          //       {item.boolean ? "TRUE" : "FALSE"}
          //     </TableWrapper.Status>
          //   ),
          // },
          // {
          //   label: "Date",
          //   type: TableWrapper.dataTypes.DATE,
          //   render: (item) => moment(item.date).format("lll"),
          // },
        ]}
        actions={[
          new TableWrapper.action(
            "Add",
            "mdi mdi-plus",
            () => {
              setSelectedItem(null);
              setShowForm(true);
            },
            true
          ),
        ]}
        itemActions={[
          new TableWrapper.action(
            "Edit",
            "mdi mdi-pencil",
            (item) => {
              setSelectedItem(item.id);
              setShowForm(true);
            },
            true
          ),
          new TableWrapper.action(
            "Delete",
            "mdi mdi-delete",
            (item) => deleteItem(item),
            true
          ),
          new TableWrapper.action(
            "Detail",
            "mdi mdi-pencil",
            (item) => {
              setSelectedItem(item.id);
              setShowDetailForm(true);
            },
            true
          ),
        ]}
        onFetchData={async (query) => {
          return new Promise(async (resolve, reject) => {
            try {
              query = {
                ...query,
                "kategori.equals": 2,
                npwpHeadId: props.match.params.npwpHeadId,
              };
              console.log(query, "QQ");
              let page = await supplierService.getPage(query);
              page.headers["x-pagination-count"] =
                page.headers["x-total-c ount"];
              resolve(page);
            } catch (e) {
              // resolve(e);
            }
          });
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={true}
      />

      <SupplierForm
        visible={showForm}
        onRequestClose={() => {
          setShowForm(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
        {...props}
      />

      <SupplierDetail
        visible={showDetailForm}
        onRequestClose={() => {
          setShowDetailForm(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
        {...props}
      />
    </>
  );
};

export default inject("modalStore")(observer(Supplier));
