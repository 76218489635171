import React, { useState } from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import moment from "moment";
import multiOutletService from "./MultiOutlet.service";
import iziToast from "izitoast";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import OutletForm from "../Outlet/OutletForm";

const MultiOutlet = ({
  className = "",
  showCommandbar = true,
  modalStore,
  navigationStore,
  ...props
}) => {
  const [showForm, setShowForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const baseId = "multi-outlet";
  const basePath = `/internal/application`;
  const npwpHeadId = props.match.params.npwpHeadId
  let premium = sessionStorage.getItem('premium');
  // console.log(props);

  var actions = [
    new TableWrapper.action(
      `${t.translate("words.add")}`,
      "mdi mdi-plus",
      () => {
        // setDisabledForm(false)
        // setSelectedItemId(null)
        setShowForm(true);
      },
      true
    ),
  ];

  const nonaktifkanOutlet = async (item) => {
    try {
      let res = await multiOutletService.updateStatus(item.id);

      let { data, status } = res;

      if (status == 200) {
        iziToast.success({
          message: "Sukses Update Outlet",
        });

        TableWrapper.reload(baseId);
      } else {
        iziToast.info({
          message: data.message,
        });
      }
    } catch (err) {
      iziToast.error({
        message: err.message
      })
    }
  };

  var itemActions = [
    new TableWrapper.action(
      `Pilih Outlet`,
      "mdi mdi-import",
      (item) => {
        // sessionStorage.setItem('npwpHead', item.npwp);
        sessionStorage.removeItem("outletId");
        sessionStorage.setItem("outletId", item.id);
        sessionStorage.setItem("outletName", item.nama);
        sessionStorage.setItem("outletCode",item.kode);
        window.location.href = `${basePath}/${npwpHeadId}/outlet/${item.id}`;
      },
      (item) => {
        if(item.status == 1){
          return true
        } else {
          return false
        }
      }
    ),
    new TableWrapper.action(
      (item) => (item.status == 1 ? `Nonaktifkan Outlet` : "Aktifkan Outlet"),
      "mdi mdi-power",

      (item) => {
        nonaktifkanOutlet(item)
      },

      true
    ),
  ];

  return (
    <>
      <TableWrapper
        baseId={baseId}
        title={"Outlet"}
        className={className}
        hintMessage={"List Outlet"}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate("modules.customer.hint.more")}
        defaultData={[]}
        defaultSort="DESC"
        defaultSortBy="tanggalDibuat"
        isShowFilter={false}
        showActionColumn={true}
        useFilter={true}
        multiColumnFilter={true}
        defaultCollapse={true}
        useCriteria={true}
        selectable={false}
        useQueryHistory={false}
        // extraFilter={<div>Test</div>}
        columns={[
          {
            label: t.translate("words.name"),
            searchable: true,
            sortable: false,
            key: "nama",
            render: (item) => item.nama,
          },
          {
            label: t.translate("words.address"),
            searchable: true,
            sortable: false,
            key: "alamat",
            render: (item) => item.alamat,
          },
          {
            label: t.translate("words.phone"),
            searchable: true,
            sortable: false,
            key: "noTelepon",
            render: (item) => item.noTelepon,
          },
          {
            label: t.translate("words.email"),
            searchable: true,
            sortable: false,
            key: "email",
            render: (item) => item.email,
          },
          {
            label: t.translate("words.status"),
            searchable: true,
            sortable: false,
            key: "status",
            definition:{
              inputType: TableWrapper.inputTypes.SELECT,
              options:[
                { value:"1", children: t.translate('words.active') },
                { value:"2", children: t.translate('words.non-active') }
              ],
            },
            render: (item) => (
              <TableWrapper.Status
                type={
                  item.status == 1
                    ? TableWrapper.Status.types.SUCCESS
                    : TableWrapper.Status.types.ERROR
                }
              >
                {item.status == 1 ? "Active" : "Non-Active"}
              </TableWrapper.Status>
            ),
          },
        ]}
        actions={actions}
        itemActions={itemActions}
        onFetchData={async (query) => {
          return new Promise(async (resolve, reject) => {
            try {
              query = {
                ...query,
                'npwpHeadId.equals': npwpHeadId
              }
              let page = await multiOutletService.getPageMultiOutlet(query);
              page.headers["x-pagination-count"] =
                page.headers["x-total-count"];
              resolve(page);
            } catch (err) {
              // resolve(err);
            }
          });
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={true}
      />
      <OutletForm
        visible={showForm}
        onRequestClose={() => {
          setShowForm(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
        {...props}
      />
    </>
  );
};

export default inject(
  "navigationStore",
  "envStore",
  "modalStore"
)(observer(MultiOutlet));
