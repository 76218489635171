import Crud from '../../libs/react-mpk/services/crud.generator'
import http from '../../libs/react-mpk/services/http.service'

let AreaService = new Crud('/area/kodepos')
let url = '/area/kodepos'

AreaService.getByKodePos = async(kodePost) => {
    return http.request({
        method: http.methods.GET,
        url: `${url}/${kodePost}`
    })
}

export {AreaService}