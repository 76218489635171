import React, { useState } from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import unitService from "./Unit.service";
import UnitForm from "./UnitForm";
import { inject, observer } from "mobx-react";
import iziToast from "izitoast";

const Unit = ({ className = "", showCommandbar = true, modalStore, ...props }) => {
  const [showForm, setShowForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const deleteUnit = async (item) => {
    try {
      let res = await unitService.deleteUnit(item.id);

      let { data, status } = res;

      if (status === 200) {
        iziToast.success({
          message: data.message,
        });

        TableWrapper.updateDataItem("unit-table", { id: data.id }, data);
        TableWrapper.reload("unit-table");
      } else {
        iziToast.info({
          message: data.message,
        });
      }
    } catch (err) {
      iziToast.error({
        message: err.message,
      });
    }
  };

  const deleteItem = async (item) => {
    modalStore.showConfirm({
      title: t.translate("words.delete"),
      children: t.translate("words.confirmHapus"),
      onSubmit: (callback) => {
        deleteUnit(item);
        callback();
      },
    });
  };

  return (
    <>
      <TableWrapper
        baseId="unit-table"
        title={t.translate("modules.kategoriUnit.unit.title")}
        className={className}
        hintMessage={t.translate("modules.kategoriUnit.unit.hint.message")}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate("modules.kategoriUnit.unit.hint.more")}
        defaultData={[]}
        defaultSortBy="tanggalDibuat"
        defaultSort="ASC"
        isShowFilter={false}
        showActionColumn={true}
        useFilter={true}
        multiColumnFilter={true}
        defaultCollapse={true}
        useCriteria={true}
        selectable={false}
        useQueryHistory={false}
        // multiColumnFilter={true}
        // extraFilter={<div>Test</div>}
        columns={[
          {
            label: t.translate("words.kode"),
            searchable: true,
            sortable: false,
            key: "kode",
            render: (item) => item.kode,
          },
          {
            label: t.translate("words.name"),
            searchable: true,
            sortable: false,
            key: "nama",
            render: (item) => item.nama,
          },
        ]}
        actions={[
          new TableWrapper.action(
            "Add",
            "mdi mdi-plus",
            () => {
              setShowForm(true);
              setSelectedItem(null);
            },
            true
          ),
        ]}
        itemActions={[
          new TableWrapper.action(
            "Edit",
            "mdi mdi-pencil",
            (item) => {
              setSelectedItem(item.id);
              setShowForm(true);
            },
            true
          ),
          new TableWrapper.action(
            "Delete",
            "mdi mdi-delete",
            (item) => deleteItem(item),
            true
          ),
        ]}
        onFetchData={async (query) => {
          return new Promise(async (resolve, reject) => {
            try {
              query = {
                ...query,
                npwpHeadId: props.match.params.npwpHeadId,
              };
              let page = await unitService.getPageUnit(query);
              page.headers["x-pagination-count"] =
                page.headers["x-total-count"];

              resolve(page);
            } catch (err) {
              // resolve(err);
            }
          });
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={true}
      />

      <UnitForm
        visible={showForm}
        onRequestClose={() => {
          setShowForm(false);
          setSelectedItem(null);
        }}
        item={selectedItem}
        {...props}
      />
    </>
  );
};

export default inject("modalStore")(observer(Unit));
