import { crud, http } from "../../libs/react-mpk/services";

let purchaseService = new crud("/pembelian");
let serviceUrl = "/pembelian";
let npwpHeadId = sessionStorage.getItem("npwpHeadId");
let posOutletId = sessionStorage.getItem("outletId");

purchaseService.createPurchase = (data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}`,
    data,
  });
};
purchaseService.purchaseGetPage = (query) => {
  query = {
    ...query,
    npwpHeadId: npwpHeadId,
  };
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}`,
    query,
  });
};
purchaseService.purchaseGetDetail = (id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/${id}`,
  });
};

purchaseService.updatePembelian = (id) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/terima_pembelian/${id}`,
  });
};

purchaseService.getProductList = async (query) => {
  query = {
    ...query,
  };
  return http.request({
    method: http.methods.GET,
    url : `/pembelian/produk`,
    query
  })
}

export default purchaseService;
