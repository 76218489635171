import React, {useState} from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import moment from 'moment'
import customerService from './Customer.service'
import iziToast from 'izitoast'
import { inject } from 'mobx-react'
import { observer } from 'mobx-react-lite'
import CustomerForm from './CustomerForm'

const Customer = ({
  className       = '',
  showCommandbar  = true,
  modalStore,
  ...props
}) => {
  const [showForm, setShowForm] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  
  const deleteCustomer = async (item) => {
    try {
      let res = await customerService.deleteCustomer(item.id);

      let { data, status } = res;

      if (status == 200) {
        iziToast.success({
          message: data.message,
        });

        TableWrapper.updateDataItem("customer-table", { id: data.id }, data);
        TableWrapper.reload("customer-table");
      } else {
        iziToast.info({
          message: data.message,
        });
      }
    } catch (err) {
      iziToast.error({
        message: err.message
      })
    }
  };

  const deleteItem = async (item) => {
    modalStore.showConfirm({
      title: t.translate("words.delete"),
      children: t.translate("words.confirmHapus"),
      onSubmit: (callback) => {
        deleteCustomer(item);
        callback();
      },
    });
  };

  return (
    <>
      <TableWrapper
        baseId="customer-table"
        title={t.translate('modules.lawanTransaksi.customer.title')}
        className={className}
        hintMessage={t.translate('modules.lawanTransaksi.customer.hint.message')}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate('modules.customer.hint.more')}
        defaultData={[]}
        defaultSortBy="tanggalDibuat"
        defaultSort="ASC"
        isShowFilter={false}
        showActionColumn={true}
        useFilter={true}
        multiColumnFilter={true}
        defaultCollapse={true}
        useCriteria={true}
        selectable={false}
        useQueryHistory={false}
        // extraFilter={<div>Test</div>}
        columns={[
          {
            label: t.translate("words.name"),
            searchable: true,
            sortable: false,
            key:'nama',
            render: item => (item.nama)
          },
          {
            label: t.translate("words.address"),
            searchable: true,
            sortable: false,
            key:'alamat',
            render: item => (item.alamat)
          },
          {
            label: t.translate("words.phone"),
            searchable: true,
            sortable: false,
            key:'noTelepon',
            render: item => (item.noTeleponPic)
          },
          {
            label: t.translate("words.email"),
            searchable: true,
            sortable: false,
            key:'email',
            render: item => (item.emailPic)
          },
          
        ]}
        actions={[
          new TableWrapper.action(
            'Add', 
            'mdi mdi-plus', 
            () => {
              setSelectedItem(null)
              setShowForm(true)
            }, 
            true
          )
        ]}
        itemActions={[
          new TableWrapper.action(
            'Edit', 
            'mdi mdi-pencil', 
            (item) => {
              setSelectedItem(item.id)
              setShowForm(true)
            }, 
            true
          ),
          new TableWrapper.action('Delete', 'mdi mdi-delete', (item) => deleteItem(item), true)
        ]}
        onFetchData={ async query => {
          return new Promise(async (resolve, reject) => {
            try{

              query = {
                ...query,
                'kategori.equals': 1,
                'npwpHeadId' : props.match.params.npwpHeadId,
                // 'nama.contains':'',
                // 'alamat.contains':'',
                // 'noTelepon.contains':'',
                // 'email.contains':''
              }

              console.log(query)

              let page = await customerService.getPageCustomer(query)
              if(page.headers["x-total-count"])
                page.headers["x-pagination-count"] = page.headers["x-total-count"];
              resolve(page)
            }
            catch(err){
              // resolve(err)
            }
          })
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={true}
      />

      <CustomerForm
        visible={showForm}
        onRequestClose={() => {
          setShowForm(false)
          setSelectedItem(null)
        }}
        item={selectedItem}
        {...props}
        />

    </>
  )
}

export default inject("modalStore")(observer(Customer));

