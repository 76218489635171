import { crud } from "../../libs/react-mpk/services";
import http from "../../libs/react-mpk/services/http.service";

let employeeService = new crud("/karyawan");
let serviceUrl = "/karyawan";

employeeService.createEmployee = async (data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}`,
    data,
  });
};

employeeService.getEmployeeById = async (id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/${id}`,
  });
};

employeeService.deleteEmployee = async (id) => {
  return http.request({
    method: http.methods.DELETE,
    url: `${serviceUrl}/${id}`,
  });
};

employeeService.updateEmployee = async (data) => {
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}`,
    data,
  });
};

employeeService.updateEmployeePin = async (data) => {
  return http.request({
    method: http.methods.PUT,
    url: `${serviceUrl}/update_pin`,
    data,
  });
};

employeeService.getAllPage = async (query) => {
  query = {
    ...query,
  };

  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}`,
    query,
  });
};

export default employeeService;
